import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
		path: '/',
		name: 'Home',
		// component: Home
		component: () => import(/* webpackChunkName: "about" */ '@/views/home/home.vue'),
		meta: {
			requireLogin: false
		},
    },
    {
		path: '/login',
		name: 'login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.

		component: () => import(/* webpackChunkName: "about" */ '@/views/login/login.vue'),
		meta: {
			hideFoot:true
		},
    },
    {
		path: '/register',
		name: 'register',component: () => import(/* webpackChunkName: "about" */ '@/views/register/register.vue'),
		meta: {
			hideFoot:true,
		},
    },
    {
		path: '/forgetPsw',
		name: 'forgetPsw',component: () => import('@/views/forgetPsw/forgetPsw.vue'),
		meta: {
			hideFoot:true,
			title:'忘记密码',
			subHead:true,
		},
    },
    {
		path: '/threePrint',
		name: 'threePrint',component: () => import('@/views/threePrint/threePrint.vue'),
		meta: {
			
		},
    },
    {
		path: '/cnc',
		name: 'cnc',component: () => import('@/views/cnc/cnc.vue'),
		meta: {
			
		},
    },
    {
		path: '/mould',
		name: 'mould',component: () => import('@/views/mould/mould.vue'),
		meta: {
			
		},
    },
    {
		path: '/aboutUs',
		name: 'aboutUs',component: () => import('@/views/aboutUs/aboutUs.vue'),
		meta: {
			
		},
    },
    {
		path: '/metal',
		name: 'metal',component: () => import('@/views/metal/metal.vue'),
		meta: {
			
		},
    },
    {
		path: '/complexmode',
		name: 'complexmode',component: () => import('@/views/complexmode/complexmode.vue'),
		meta: {
			
		},
    },
    {
		path: '/case',
		name: 'case',component: () => import('@/views/case/case.vue'),
		meta: {
			
		},
    },
    {
		path: '/caseInfo',
		name: 'caseInfo',component: () => import('@/views/caseInfo/caseInfo.vue'),
		meta: {
			
		},
    },
    {
		path: '/news',
		name: 'news',component: () => import('@/views/news/news.vue'),
		meta: {
			
		},
    },
    {
		path: '/newsInfo',
		name: 'newsInfo',component: () => import('@/views/newsInfo/newsInfo.vue'),
		meta: {
			
		},
    },
    {
		path: '/print',
		name: 'print',component: () => import('@/views/print/print.vue'),
		meta: {
			
		},
	},
    {
		path: '/personage',
		name: 'personage',component: () => import('@/views/personage/personage.vue'),
		meta: {
			
		},
	},
    {
		path: '/personalData',
		name: 'personalData',component: () => import('@/views/personalData/personalData.vue'),
		meta: {
			
		},
	},
    {
		path: '/myAddress',
		name: 'myAddress',component: () => import('@/views/myAddress/myAddress.vue'),
		meta: {
			
		},
	},
    {
		path: '/generalize',
		name: 'generalize',component: () => import('@/views/generalize/generalize.vue'),
		meta: {
			subHead:true,
			title:'个人推广'
		},
	},
    {
		path: '/myVoucher',
		name: 'myVoucher',component: () => import('@/views/myVoucher/myVoucher.vue'),
		meta: {
			subHead:true,
			title:'代金券'
		},
	},
    {
		path: '/invoiceInfo',
		name: 'invoiceInfo',component: () => import('@/views/invoiceInfo/invoiceInfo.vue'),
		meta: {
			subHead:true,
			title:'发票信息'
		},
	},
    {
		path: '/cart',
		name: 'cart',component: () => import('@/views/cart/cart.vue'),
		meta: {
			subHead:true,
			title:'购物车',
			hideFoot:true
		},
	},
    {
		path: '/submitOrder',
		name: 'submitOrder',component: () => import('@/views/submitOrder/submitOrder.vue'),
		meta: {
			subHead:true,
			title:'提交订单',
			hideFoot:true
		},
	},
    {
		path: '/pay',
		name: 'pay',component: () => import('@/views/pay/pay.vue'),
		meta: {
			subHead:true,
			title:'支付订单',
			hideFoot:true
		},
	},
    {
		path: '/myOrder',
		name: 'myOrder',component: () => import('@/views/myOrder/myOrder.vue'),
		meta: {
			subHead:true,
			title:'我的订单',
			hideFoot:true
		},
	},
    {
		path: '/supplementalInvoice',
		name: 'supplementalInvoice',component: () => import('@/views/supplementalInvoice/supplementalInvoice.vue'),
		meta: {
			subHead:true,
			title:'补开发票',
			hideFoot:true
		},
	},
    {
		path: '/orderDetail',
		name: 'orderDetail',component: () => import('@/views/orderDetail/orderDetail.vue'),
		meta: {
			subHead:true,
			title:'订单详情',
			hideFoot:true
		},
	},
    {
		path: '/printNotice',
		name: 'printNotice',component: () => import('@/views/printNotice/printNotice.vue'),
		meta: {
			// subHead:true,
			// title:'打印须知',
		},
	},
    {
		path: '/commission',
		name: 'commission',component: () => import('@/views/commission/commission.vue'),
		meta: {
			subHead:true,
			title:'推广提成',
			hideFoot:true
		},
	},
    {
		path: '/expend',
		name: 'expend',component: () => import('@/views/expend/expend.vue'),
		meta: {
			subHead:true,
			title:'支出详情',
			hideFoot:true
		},
	},
    {
		path: '/income',
		name: 'income',component: () => import('@/views/income/income.vue'),
		meta: {
			subHead:true,
			title:'收入详情',
			hideFoot:true
		},
	},
    {
		path: '/withdraw',
		name: 'withdraw',component: () => import('@/views/withdraw/withdraw.vue'),
		meta: {
			subHead:true,
			title:'提现记录',
			hideFoot:true
		},
	},
    {
		path: '/applyExtract',
		name: 'applyExtract',component: () => import('@/views/applyExtract/applyExtract.vue'),
		meta: {
			subHead:true,
			title:'申请提现',
			hideFoot:true
		},
	},
    {
		path: '/paySuccess',
		name: 'paySuccess',component: () => import('@/views/paySuccess/paySuccess.vue'),
		meta: {
			subHead:true,
			title:'支付成功',
			hideFoot:true
		},
	},
    {
		path: '/contrast',
		name: 'contrast',component: () => import('@/views/contrast/contrast.vue'),
		meta: {
		},
	}
]

const router = new VueRouter({
  	routes,
	mode: "history"
})

export default router
