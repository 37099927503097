import axios from 'axios'
import router from '../router/index'
import { Toast } from 'vant';
export var baseURL = ''

if (process.env.NODE_ENV === 'development') {
    // baseURL = '/api' // （解决跨域）在根目录下config/index.js里proxyTable的target配置路径
    // baseURL = 'http://192.168.181.97:9271' // 龚爽
    baseURL = 'https://m.qileshouban.com/website' // 外网
} else if (process.env.NODE_ENV === 'production') {
    baseURL = 'https://m.qileshouban.com/website' // 外网
}

function setCookie(cname, cvalue, exdays) {
  //cname 名字
  //cvalue 值
  //exdays 时间            0.01大概25分钟
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires+"; path=/"   //这个很重要代表在那个层级下可以访问cookie
}

function getCookie(name) {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
      return unescape(arr[2]);
  else
      return null;
}

const service = axios.create({
    baseURL: baseURL,
    // headers: {'token': getCookie('_token')},
    withCredentials: false, // 设置是否跨域
    timeout: 60000
})

service.defaults.headers.get['Content-Type'] = 'application/json; charset=UTF-8'

// 请求拦截，可携带Token请求头
service.interceptors.request.use(
    config => {
      if(getCookie('_token')){
        config.headers.common['token'] = getCookie('_token')
      }
      delete config.data.token  //删除请求的token参数（非请求头）
      return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    response => {
		const res = response.data
		let fullPath = ''
		//   store.dispatch('app/changeSpingFn', false)
    if(res.code == 0){
      	return res
    }else if(res.code == 60){
		if(res.obj == "token失效，请重新登录" || res.obj == "token不能为空"){
			setCookie('_token', "", -1)
			setCookie('_isMonthlyKnots', "", -1)
			// res.msg = "token已过期，请重新登录"
			res.msg
			// alert("token失效，请重新登录")
			fullPath = location.pathname
			if(location.hash.indexOf('/login') == -1){
			// router.replace({
			// 	path: '/'
			// })
			router.push({
				path: '/login',
				query: {
				fullPath
				}
			})
			}
			// location.href = location.host+'/#/login?fullPath='+fullPath
			return res
		}
    }else{
      // Toast(res.msg);
      Toast.fail(res.msg);
      // this.$toast(res.msg)
        if (res.code == -2) {
        //   store.dispatch('user/noPowerFn', res.message)
        }
        return Promise.reject(res || '服务器超时')
    }
      // if (res.code !== 0 && res.code !== 200 && res.code !== 20) {
      //   console.log(res)
      //   if (res.code == -2) {
      //   //   store.dispatch('user/noPowerFn', res.message)
      //   }
      //   return Promise.reject(res.msg || '服务器超时')
      // } else {
      //   return res
      // }
    },
    error => {
    //   store.dispatch('app/changeSpingFn', false)
      if (error || error.response) {
        if (error.response.status == 401 || error.response.status == 403) {
          if (error.response.data.code == -3 || error.response.data.code == 403) {
            // router.push('/401')
          }
        }
        if (error.response.status !== 200) {
          console.log(error)
        }
        // error.message = errorText[error.response.status] || `连接错误${error.response.status}`
      } else {
       console.log('失败')
      }
      return Promise.reject(error)
    }
  )

  export default service
