<template>
	<div class="myFoot">
        <van-tabbar v-model="active" @change="onChange">
            <van-tabbar-item icon="home-o">
                <span>首页</span>
                <template #icon="props">
                <img :src="props.active ? icon.foot1 : icon.footed1" />
                </template>
            </van-tabbar-item>

            
            <van-tabbar-item icon="search" @click="crafClick">
                <span>工艺</span>
                <template #icon="props">
                <img :src="props.active ? icon.foot2 : icon.footed2" />
                </template>
            </van-tabbar-item>
            <!-- <van-tabbar-item icon="friends-o">
                <span>自动报价</span>
                <template #icon="props">
                <img :src="props.active ? icon.foot3 : icon.footed3" />
                </template>
            </van-tabbar-item> -->
              <van-tabbar-item icon="setting-o">
                <span>全景工厂</span>
                <template #icon="props">
                <img :src="props.active ? icon.foot6 : icon.footed6" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item icon="setting-o" @click="moreClick">
                <span>更多</span>
                <template #icon="props">
                      <img :src="props.active ? icon.foot5 : icon.footed5" />
               
                </template>
            </van-tabbar-item>
            <!-- <van-tabbar-item icon="setting-o">
                <span>我的</span>
                <template #icon="props">
                <img :src="props.active ? icon.foot5 : icon.footed5" />
                </template>
            </van-tabbar-item> -->
        </van-tabbar>

        <van-popup v-model="crafList" position="bottom" :style="{ height: '5.4rem' }">
            <div class="navListBox">
                <p class="title"><img src="../assets/image/footed2.png" alt=""> 工艺 
                <van-icon @click="crafList=false" class="close" name="close" size="21" /></p>
                <p><router-link to="/threePrint">3D打印</router-link></p>
                <p><router-link to="/cnc">CNC加工</router-link></p>
                <p><router-link to="/metal">钣金</router-link></p>
                <p><router-link to="/complexmode">硅胶复模</router-link></p>
                <p><router-link to="/mould">快速模具</router-link></p>
            </div>
        </van-popup>
        <van-popup v-model="more" position="bottom" :style="{ height: 'auto' }">
            <div class="navListBox">
                <p class="title"><img src="../assets/image/footed4.png" alt=""> 更多 <van-icon @click="more=false" class="close" name="close" size="21" /></p>
                <p><router-link to="/aboutUs">关于我们</router-link></p>
                <p><router-link to="/case">行业案例</router-link></p>
                <p><router-link to="/news">新闻动态</router-link></p>
                <!-- <p><a href="https://www.qilemodel.com/assets/quanjing/index.html">全景工厂</a></p> -->
            </div>
        </van-popup>
	</div>

</template>

<script>
export default {
    name: 'myFoot',
    data () {
        return {
            active: 0,
            crafList:false,//工艺
            more:false,//更多
            icon: {
                foot1: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0301/HBi4ehXMQd.png',
                footed1: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0301/Nx2DdSbpnj.png',
                foot2: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0302/MA7AjDtDmH.png',
                footed2: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0302/JD7hcGrs5D.png',
                foot3: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0302/hN5XcAyirT.png',
                footed3: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0302/CEffsmfBjQ.png',
                foot4: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0302/aCYYnHB3pm.png',
                footed4: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/0302/FDp3bQPFGe.png',
                foot5: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/1213/AfxWZJaEtR.png',
                footed5: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/1213/wjNMFwFBY6.png',
                foot6: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/1213/7ATQ7twt2x.png',
                footed6: 'https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2021/1213/JsEJr8K4xR.png',
            },
        }
    },

    watch: {
        $route: {
            handler: function (val, oldVal) {
                this.crafList = false
                this.more = false
                if(val.name == 'Home'){
                console.log('首页');
                    this.active = 0
                }else{
                       console.log('fei首页');
                }
            },
            // 深度观察监听
            deep: true
        }
    },

    methods: {
        onChange(index) {
            console.log(index);
            this.more=false
            this.crafList=false
            if(index==0){
                this.$router.push({
                    path: '/',
                })
            }else if(index==2){
                // this.$router.push({
                //     path: '/print',
                // })
              window.location.href="https://www.qilemodel.com/assets/quanjing/index.html"
            }else if(index==4){
                this.$router.push({
                    path: '/personage',
                })
            }
        },
        crafClick(){
            this.more=false
            this.crafList=true
        },
        moreClick(){
            this.more=true
            this.crafList = false
        }
    },

    created () {
  console.log(this.$route.fullPath);  
  var path=this.$route.fullPath
  if(path==''){
  this.active=0
  }else if(path=='/threePrint'||path=='/cnc'||path=='/metal'||path=='/complexmode'||path=='/mould'){
 this.active=1
  }else{
      this.active=3
  }
    },

    mounted () {
        
    }
}
</script>

<style lang="scss">
    .myFoot {
        .van-tabbar{
            z-index: 9999;
            background: linear-gradient(0deg, #000000 0%, #2C2D32 100%);
            .van-tabbar-item {
                color: #fff;
            }
            .van-tabbar-item--active{
                background: linear-gradient(0deg, #000000 0%, #2C2D32 100%);
                color: #DA251D;
            }
        }
        .van-overlay,.van-popup--bottom {
            bottom: 1rem;
        }
        .navListBox {
            max-height: 9rem;
            >p {
                width: 100%;
                line-height: 0.9rem;
                font-size: .26rem;
                color: #fff;
                background-color: #2C2D32;
                border-bottom: 1px solid #666666;
                a{
                    color: #fff;
                    padding-left: 1rem;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
                .vux-x-icon {
                    // vertical-align: middle;
                    margin-top: .18rem;
                    float: right;
                    margin-right: 0.24rem;
                    fill: #1FBAEA;
                }
            }
            .title {
                padding-left: 0.35rem;
                background-color: #171717;
                img {
                    vertical-align: middle;
                    margin-right: 0.1rem;
                    width: 0.36rem;
                }
                .close {
                    float: right;
                    margin-right: 0.3rem;
                    vertical-align: middle;
                    margin-top: 0.25rem;
                }
            }
            .printServe {
                >p {
                    width: 100%;
                    line-height: 0.9rem;
                    font-size: .26rem;
                    color: #fff;
                    background-color: #213F4D;
                    border-bottom: 1px solid #184659;
                    a{
                        color: #fff;
                        padding-left: 1.5rem;
                    }
                }
            }
        }
    }
</style>
