<template>
	<div>
		<div v-if="!subHead" class="myHead">
			<img @click="home" class="logo" src="../assets/image/logo.png" alt="">
			<div class="phone">
				<img src="../assets/image/headPhone.png" alt="">
				<a class="clickPhone" href="tel:400-139-2929">
					<p>全国服务热线</p>
					<p>400-106-1929</p>
				</a>
			</div>
		</div>
		<div v-else class="subHead">
			<van-icon @click="back" size="23" name="arrow-left" />
			<span class="title">{{subHeadText}}</span>
		</div>
	</div>
    
	
</template>

<script>

export default {
	name: 'myHead',
    props: {
      	msg: String
	},
	data () {
        return {
			subHead: false,
			subHeadText:'',
        }
    },
	watch: {
		$route(to, from) {
			this.$route.meta.subHead?this.subHead=true:this.subHead=false
			this.subHeadText = this.$route.meta.title
		}
	},
	methods:{
		back () {
            this.$router.go(-1)
        },
		home(){
			this.$router.push({
				path: '/',
			})
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.myHead{
		width: 100%;
		height: 1.20rem;
		line-height: 1.2rem;
		background: linear-gradient(0deg, #000000 0%, #2C2D32 100%);
		padding-left: 0.3rem;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		.logo {
			width: 3.06rem;
			height: 0.46rem;
			vertical-align: middle;
		}
		.phone {
			float: right;
			vertical-align: middle;
			img {
				width: 0.42rem;
				height: 0.42rem;
				vertical-align: middle;
			}
			>a {
				display: inline-block;
				vertical-align: middle;
				margin-right: 0.3rem;
				P{
					font-size: .24rem;
					line-height: 0.35rem;
					color: #fff;
					&:nth-child(2){
						color: #DA251D;
						font-weight: 900;
					}
				}
			}
		}
	}
	.subHead {
		width: 100%;
		height: 0.9rem;
		background: #060707;
		color: #fff;
		font-size: .37rem;
		position: relative;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		.title {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
		.van-icon {
			position: absolute;
			top: 50%;
			left: 0.3rem;
			transform: translate(-50%,-50%);
		}
	}
</style>
